<template>
  <vx-card :title="this.pageTitle">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="tutorials" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="pageName">Nombre</vs-th>
            <vs-th>Enlace</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td><p class="font-medium">{{ record.name }}</p></vs-td>
              <vs-td>
                <div class="flex">
                  <vs-button
                    size="small" color="success" type="border" icon-pack="feather" icon="icon-film"
                    @click="goToTutorial(record.url)"
                  >
                    Ver tutorial
                  </vs-button>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  export default {
    data() {
      return {
        pageTitle: "Tutoriales",
        tutorials: [
          {
            name: "Builder - Introducción",
            url: "https://drive.google.com/file/d/1BtAVRIXYjok06x5LpbBZyX5EnX89e4ir/view?usp=sharing",
          },
          {
            name: "Builder - Botones",
            url: "https://drive.google.com/file/d/1Y8Y7UQY45IDCr7SEf0w1GngLvC0f1NHO/view?usp=sharing",
          },
          {
            name: "Builder - Encabezados",
            url: "https://drive.google.com/file/d/16ahJ7wuRSrdXWw3fUZpkSaxLkG58_-Z4/view?usp=sharing",
          },
          {
            name: "Builder - Secciones y Banners",
            url: "https://drive.google.com/file/d/1jdtPADxbJWzogNJ13U2hkDGukD9Zg3fE/view?usp=sharing",
          },
          {
            name: "Builder - Tablas",
            url: "https://drive.google.com/file/d/1zdwzNimMicjD1wAy198WuFX3Sidlvm7o/view?usp=sharing",
          },
          {
            name: "Builder - Tarjetas (Primera parte)",
            url: "https://drive.google.com/file/d/16dYPrxyOlIYMnNfkvLXG7yd--EByQ9ew/view?usp=sharing",
          },
          {
            name: "Builder - Tarjetas (Segunda parte)",
            url: "https://drive.google.com/file/d/1YpY8GDpV27erAxG2BMV89v57hJ737DGH/view?usp=sharing",
          },
          {
            name: "Builder - Tarjetas (Tercera parte)",
            url: "https://drive.google.com/file/d/1eFuvHYMiqk2PwucJ6fNrMLVufVLd6i7d/view?usp=sharing",
          },
          {
            name: "Builder - Textos",
            url: "https://drive.google.com/file/d/1EU9vNkGqAdhmlLbbP0wj8QEtCi1K_E0Y/view?usp=sharing",
          },
          {
            name: "Builder - Ejemplo completo",
            url: "https://drive.google.com/file/d/1nZympA5e3lBLpOvqPsSGBruWWsNiJ7li/view?usp=sharing",
          },
          {
            name: "Exportar CSV a Excel",
            url: "https://drive.google.com/file/d/17QpvkLWW3_QTzgxmexs2kn1tvE5MVnux/view?usp=sharing",
          },
          {
            name: "Reportes",
            url: "https://drive.google.com/file/d/1Gmo4BQRPJEcQ_JOTDGQ5ymvZe60u1KKR/view?usp=sharing",
          },
          {
            name: "Activos Eventuales",
            url: "https://drive.google.com/file/d/13LJrJ_J7j-13piv6Ci6FLm5xq-k9tV8V/view?usp=sharing",
          },
          {
            name: "Agencias y Sucursales",
            url: "https://drive.google.com/file/d/1QNORMjYR-Xjl4jZR_DvX1b8PJveqrcBo/view?usp=sharing",
          },
          {
            name: "Banners y Slides",
            url: "https://drive.google.com/file/d/1UfU4M_2ve6H8MwdrJsQV-cXrMlS4klaQ/view?usp=sharing",
          },
          {
            name: "Ciudades",
            url: "https://drive.google.com/file/d/1VYXWRVXjhLhcdKDATWZ96CF41xKmUPF9/view?usp=sharing",
          },
          {
            name: "Descuentos",
            url: "https://drive.google.com/file/d/1__WrkKh-11IF8Cgis_S6xKb-lRMUUF8_/view?usp=sharing",
          },
          {
            name: "Documentos",
            url: "https://drive.google.com/file/d/1iHreXsHRnUiePJS7yaU_lgoyIgiuJDjO/view?usp=sharing",
          },
          {
            name: "Glosario",
            url: "https://drive.google.com/file/d/10fVIVATUqERrysMyBoiwOZ_Uj3Iu4ALW/view?usp=sharing",
          },
          {
            name: "Landings de Artículos Promocionales",
            url: "https://drive.google.com/file/d/15MvhiuoVFNcxeURT8QTf-BAnqlbLQ8IX/view?usp=sharing",
          },
          {
            name: "Login",
            url: "https://drive.google.com/file/d/1T6jhH8cCz2gnUx9f-geOYUvKGpHqZgWq/view?usp=sharing",
          },
          {
            name: "Páginas Estáticas",
            url: "https://drive.google.com/file/d/17hjXIMIMW0d9HIvRlQbVJSK0s4B0HEB_/view?usp=sharing",
          },
          {
            name: "Plazas Disponibles",
            url: "https://drive.google.com/file/d/1Z6_lfU1QCMyg5aZacfd39_eQZ_-irs6r/view?usp=sharing",
          },
          {
            name: "Productos",
            url: "https://drive.google.com/file/d/1C_ChGJBEEDsogx-mYf-qD12GJU07wBMO/view?usp=sharing",
          },
          {
            name: "Promociones",
            url: "https://drive.google.com/file/d/1fI8xIkHd2yYaQtz4iWyaw1WSCrOZFkZe/view?usp=sharing",
          },
          {
            name: "Proveedores",
            url: "https://drive.google.com/file/d/1C0uQEca5XxBPtT_GcVuL23tL40M7i8Ul/view?usp=sharing",
          },
          {
            name: "Proyectos Inmobiliarios",
            url: "https://drive.google.com/file/d/1Mgpzv49b-uWsHYKPFqKDXqtik2u1JMWF/view?usp=sharing",
          },
          {
            name: "Salvamentos",
            url: "https://drive.google.com/file/d/1G2xakpoaK8LMXmlkD14G7VY-MaBCIF41/view?usp=sharing",
          },
          {
            name: "Stages",
            url: "https://drive.google.com/file/d/1eMJO8QwwoBzWD_6LbGWplfjE7voxcyy0/view?usp=sharing",
          },
          {
            name: "Zona de Pagos",
            url: "https://drive.google.com/file/d/19vu0gprXXVqw06hxEAeA-cVJL3H1IWDC/view?usp=sharing",
          },
        ],
      };
    },

    methods: {
      goToTutorial(url) {
        window.open(url);
      }
    },
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
